<template>
    <div>
        <ListaTags></ListaTags>
    </div>

</template>

<script>
import BaseView from '@/template/BaseView.vue';
import ListaTags from '@/components/Tags/ListaTags.vue';

export default {
  components: {
    BaseView,
    ListaTags
  },
  created() {
    // if (this.$store.getters.user.user.level == 'blogger' ) {this.$router.push("/dynamicRoute/AccessDenied")};
  },
}
</script>