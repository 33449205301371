<template>
  <div class="container">
    <BaseHeader title="Gestão de tags" :navigation="navigation"> </BaseHeader>

    <div class="container-table">
      <div class="box-total" v-show="!totalLoading">
        <div>
          <p class="total-vendas">
            Total de tags:
            <span>{{ total }}</span>
          </p>
        </div>
        <div></div>
      </div>
      <div v-show="totalLoading">
        <b-skeleton
          style="border-radius: 10px"
          width="100%"
          height="83px"
          class="mt-1"
        ></b-skeleton>
      </div>

      <div class="header-table">
        <b-col
          cols="12"
          md="3"
          style="width: 100%;justify-content:space-between;gap: 10px;"
          class="container-pesquisa"
          :class="{ pd: isMobile }"
        >
          <div>
            <img src="@/assets/img/icons/search.svg" class="Pointer search" />
            <input
              type="text"
              placeholder="Pesquisar tag"
              @input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
          <div >
            <BaseSelect
                :selectModel="typeOrder"
                :array="typesList"
                trackid="value"
                trackname="text"
                returnid="value"
                style="width: 280px;"
                selectLabel=""
                :allowEmpty="false"
                deselectLabel=""
                @change="changedType"
              />
              <div v-if="typeOrder == 'Color'"class="mt-1 colors" style="width: 100%;position: relative;">
                <div v-for="color in colors" :class="`${color} ${color == selectedColor ? 'selected-color' : ''}`" @click="changeColor(color)">{{color == selectedColor ? '✓' : ''}}</div>
              </div>
          </div>
        </b-col>
        
        <div class="buttons" v-if="!loading">
          <BaseButton
            @click="$router.push({ name: 'TagAutomacao' })"
            variant="info"
            v-if="!isMobile && $store.getters.user.user.level != 'seller'"
          >
            Automação de tags
          </BaseButton>

          <BaseButton
            @click="openModal('add-tag', null)"
            variant="primary"
            v-if="!isMobile"
          >
            Adicionar uma tag
          </BaseButton>
        </div>

        <div class="buttons" v-else>
          <b-skeleton
            width="193px"
            height="50px"
            style="border-radius: 10px"
          ></b-skeleton>

          <b-skeleton
            width="205px"
            height="50px"
            style="border-radius: 10px"
          ></b-skeleton>
        </div>
      </div>
      <template>
        <div
          v-if="!loading && tags.length > 0"
          class="paginas-conteudo-container"
          :class="{ paginasMobile: isMobile }"
        >
          <div
            v-for="(item, index) in tags"
            :key="index"
            class="paginas-conteudo"
          >
            <!-- WEB -->
            <div
              v-if="!isMobile"
              @click="$store.getters.user.user.level != 'seller' ? openModal('add-tag', item) : ''"
              class="tag-body"
              :class="{ 'mb-4': !isMobile, padding: isMobile }"
            >
              <div
                v-if="!isMobile"
                class="d-flex justify-content-between d-flex align-items-center"
              >
                <h4 class="tag-title" style="width:fit-content;" v-b-tooltip="{
                      title: 'Tag (Quantidade de Leads)',
                      placement: 'bottom',
                      boundaryPadding: 20
                    }">  {{
                    item.name && item.name.length > 20
                      ? item.name.substr(0, 20) + "..."
                      : item.name
                  }}
                    <span>
                  ({{ item.leads_active }})
                  </span>
              </h4>
                <div
                  class="tag-radio"
                  :class="item.color == null ? '' : item.color"
                ></div>
              </div>
              <p style="font-size: 13px">{{ item.created_at | datetimem }}</p>
            </div>
            <!-- MOBILE -->
            <div
              v-if="isMobile"
              class="tag-body"
              :class="{ 'mb-4': !isMobile, 'pd-mg': isMobile }"
            >
              <div v-if="isMobile" class="flex">
                <div class="flex-c">
                  <h4 class="tag-titleMobile">{{ item.name }}</h4>
                  <p>
                    {{ item.created_at | datetimem }}
                  </p>
                </div>
                <div
                  class="tag-radio"
                  :class="item.color == null ? '' : item.color"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="loading"
          class="paginas-conteudo-container"
          :class="{ paginasMobile: isMobile }"
        >
          <div v-for="(item, index) in 20" :key="index">
            <b-skeleton
              class="tag-body"
              style="background-color: rgba(0, 0, 0, 0.12)"
            ></b-skeleton>
          </div>
        </div>
        <b-row
          v-if="!loading && !tags.length"
          class="Table-body justify-content-center"
        >
          <p class="nao-encontrado">Nenhuma tag foi encontrada</p>
        </b-row>
      </template>
      <AddTag :dadosTag="dadosTag" @add-tag="fetchTagService()" />
      <!-- END BODY -->
      <!-- modals -->

      <b-row>
        <b-col>
          <Paginate
            v-if="tags.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
            :currentPerPage="pagination.perPage"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import Paginate from "@/components/Paginate";
import BaseHeader from "@/components/BaseHeader";
import BaseButton from "@/components/BaseButton";
import AddTag from "@/components/Tags/AddTag";
import ListarTagService from "@/services/resources/ListarTagService";
const serviceListarTag = ListarTagService.build();
import DateRangePicker from "vue2-daterange-picker";
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "ListaTags",
  components: {
    BaseButton,
    Paginate,
    BaseHeader,
    DateRangePicker,
    AddTag,
  },
  data() {
    return {
      navigation: [
        { link: "Aplicativos", to: "/dynamicRoute/apps" },
        { link: "Tags", to: this.$route.fullPath },
      ],
      typesList: [
        { value: "Desc", text: "Mais recentes primeiro" },
        { value: "Asc", text: "Mais antigas primeiro" },
        { value: "more", text: "Mais leads" },
        { value: "less", text: "Menos leads" },
        { value: "Color", text: "Filtrar por cores" },
      ],
      typeOrder:'Desc',
      config_change_tag: "",
      colors: ["primary","info","warning","danger", "default"],
      tags: [],
      selectedColor:'',
      dadosTag: [],
      reloadTable: true,
      sale_id: -1,
      search: null,
      color: null,
      loading: true,
      totalLoading: true,
      loading_reports: true,
      totalPages: 0,
      total: 0,
      itens: 0,
      filters: {},
      client: {
        width: 0,
      },
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 20,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    changeColor(color){
      if(this.selectedColor == color){
        this.selectedColor = '';
      }else{
        this.selectedColor = color;
      }
      this.fetchTagService();  
    },
    fetchTagService(search = null, page = 1) {
      this.loading = true;
      this.pagination.totalPages = 1;
      let data = {
        page: page,
        per_page: this.pagination.perPage,
        color: this.typeOrder === 'Color' && this.selectedColor ? this.selectedColor : '',
        type: ['more', 'less', 'Color'].includes(this.typeOrder) ? this.typeOrder : undefined,
        orderBy: this.typeOrder === 'more' || this.typeOrder === 'Color' ? 'Desc' : this.typeOrder === 'less' ? 'Asc' : this.typeOrder
      };

      if (data.type === undefined) {
        delete data.type;
      }

      if (this.search) {
        data.search = this.search;
      }

      serviceListarTag
        .search(data)
        .then((response) => {
          this.tags = response.data;
          this.total = response.total;
          this.pagination.totalPages = response.last_page;
          this.pagination.currentPage = response.current_page;
          this.pagination.perPage = response.per_page;
        })
        // .catch((error) => // console.log(error))
        .finally(() => {
          this.loading = false;
          this.totalLoading = false;
        });
    },
    changedType(event) {
      this.typeOrder = event;
      this.fetchTagService(this.search, 1)
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchTagService(this.search, page);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchTagService(this.search);
    },
    openModal(data, dados) {
      this.$bvModal.show(data);
      this.dadosTag = dados;
    },

    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },

    debounce: _.debounce(function () {
      this.fetchTagService(this.search);
    }, 500),
    fetchConfigSeller() {
      var data = {
        id: "seller",
      };
      return serviceSettings.read(data).then((resp) => {
        for (let i = 0; i < resp.length; i++) {
          const seller = resp[i];
          if (seller.meta_key === "change_tag") {
            this.config_change_tag = seller.meta_value;
          }
        }
      });
    },
  },
  created() {
    this.fetchConfigSeller().then(() => {
      if (
        this.$store.getters.user.user.level == "seller" &&
        this.config_change_tag == "false"
      ) {
        this.$router.push("/dynamicRoute/AccessDenied");
      }
    });
  },
  mounted() {
    this.loading = true;
    this.totalLoading = true;
    this.fetchTagService();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  gap: 15px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-c {
  display: flex;
  flex-direction: column;
  p {
    font-size: 13px;
    @media (max-width: 768px) {
      font-size: 11.5px;
    }
  }
}
.pd-mg {
  padding: 15px !important;
  margin: 0px !important;
}
.paginasMobile {
  grid-template-columns: repeat(2, 1fr) !important;
  margin-top: 10px !important;
}
.pd {
  padding-right: 0;
  padding-left: 0;
}
.paginas-menu {
  border-radius: 10px;
  border: none;
}
.margin {
  height: 60px !important;
}
input {
  margin-bottom: 0 !important;
}
.paginas-descricao-opt {
  color: var(--greenn);
}
.paginas-descricao-titulo {
  font-weight: 600;
  font-size: 14px;
  color: var(--gray01);
}
.paginas-descricao {
  padding: 30px 30px;
}
.paginas-conteudo-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}
.tag-body {
  border-radius: 10px;
  background-color: #f7f7f7;
  padding: 20px;
  margin: 5px;
  cursor: pointer;
  height: 100px;
}
.tag-radio {
  width: 13px;
  height: 13px;
  background-color: black;
  border-radius: 50%;
}
.tag-titleMobile {
  font-size: 16px;
  word-break: break-all;
  width: 95px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}
.tag-title {
  font-size: 16px;
  width: calc(100% - 45px);
  overflow-x: hidden;
}
.info {
  background: var(--bluee);
}
.danger {
  background: var(--red);
}
.warning {
  background: var(--orange);
}
.primary {
  background: var(--greenn);
}
.success {
  background: var(--bluee);
  // white-space: nowrap;
}
.default {
  background: black;
  // white-space: nowrap;
}
.img-conteudo {
  width: 408px;
  height: 217.42px;
  border-radius: 5px 5px 0 0;
}
.img-icon {
  width: 12px;
  height: 13.5px;
}
.display-flex2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.display-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.nova-pagina {
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  padding: 20px 40px;
  gap: 10px;
  background: var(--greenn);
  border-radius: 10px;
  border: none;
}
.box-total {
  justify-content: center;
}
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: var(--greenn2);
  margin-right: 15px;
}
.btn-table:first-child:active {
  background: var(--greenn2);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(3) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 175px;
  font-size: 14px !important;
  color: #81858e !important;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: auto;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 30px;
  top: 18px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.container-pesquisa {
  position: relative;
  display: flex;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: var(--greenn);
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}
.inverted--black {
  filter: invert(100);
}
.title-report {
  color: var(--gray01);
  font-weight: bold;
  font-size: 20px;
}
.title-report-margin-top {
  margin-top: 41px;
}
.title-report-margin-bottom {
  margin-top: 41px;
}

.default {
  background: black;
  // white-space: nowrap;
}

div#checkbox-group-all {
  position: relative;
  left: -5px;
}
.colors {
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 200px;
  > div {
    width: 45px;
    aspect-ratio: 1;
    border-radius: 7px;
    cursor: pointer;
    transition: all .3s;
    border: 1px solid #F7F7F7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    &:hover {
      box-shadow: inset 0 0 0px 2.5px #fff;
    }
  }
  .selected-color {
    box-shadow: inset 0 0 0px 2.5px #fffb, 2px 2px 3px 1px #0002;
    color: #fffb !important;
  }
  .inputSearch{
    width: 19.5vw;
  }
}
</style>
